export default class {
  execute(properties, component) {
    const data = {}
    const cycleStart = properties['setting[cycle_start]']
    if (cycleStart !== undefined) {
      data[$tpu.keys.brdCycleStart()] = cycleStart
    }
    const cycleDuration = properties['setting[cycle_duration]']
    if (cycleDuration !== undefined) {
      data[$tpu.keys.brdCycleDuration()] = cycleDuration
    }

    const t = TrelloPowerUp.iframe()
    $tpu.r.data.set(t, 'board', 'shared', data).then(() => {
      GLib.action.execute(properties.onSuccess, component);
    })
  }
}
